<template>
  <div class="card">
    <div class="card-body">
      <div class="general-settings">
        <div class="row">
          <div class="col-md-7">
            <status-page-language-form mode="update" :status-page="statusPage"  />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusPageLanguageForm from '@/components/Forms/StatusPageForm/StatusPageLanguageForm.vue'

export default {
  components: {
    StatusPageLanguageForm
  },

  metaInfo: {
    title: 'Language Settings'
  },

  props: {
    statusPage: {
      required: true,
      type: Object
    }
  }
}
</script>
